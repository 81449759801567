export const ROUTES_TRANSLATIONS = {
  site_title: 'Seez.dk',
  Home: {
    title: 'Seez - Your online dealer of almost new cars',
    description: 'At Seez you will find used cars that are as good as new. See our selection of quality cars and find your almost new car'
  },
  Search: {
    path: 'search',
    title: 'Find your almost new car at',
    description: 'All used cars from Seez are carefully selected and live up to our high standards of quality. Find your next car in Seez\'s great car universe'
  },
  Listing: {
    path: 'listing'
  },
  TradeIn: {
    path: 'tradein',
    title: 'Exchange your car at the store. Calculate exchange price',
    description: 'Use your old car as part of the financing for your new one. With Seez\'s barter, you get an offer within a few hours. Apply easily and quickly online'
  },
  Financing: {
    path: 'financing',
    title: 'Car Financing » Get a car loan through Seez',
    description: 'Finance your new car with an attractive loan from our partner Santander. See your loan options and calculate your car loan here'
  },
  Terms: {
    path: 'terms',
    title: 'Terms and Conditions',
    description: 'Read Seez Denmark\'s terms and conditions.Find information about i.a.copyright and disclaimer.'
  },
  Cookies: {
    // path: '',
    title: 'Cookie Policy',
    description: 'At Seez.dk we use cookies to optimize the user experience. You have the ability to manage your cookie preferences.'
  },
  Privacy: {
    path: 'privacy',
    title: 'Privacy Policy',
    description: 'Privacy policy for Seez Denmark applies to all personal information collected and processed by Seez.'
  },
  Searches: {
    path: 'searches',
    title: 'My saved searches',
    description: 'See your recent searches on Seez and return to the car models you have previously shown interest in'
  },
  Favorites: {
    path: 'favorites',
    title: 'My favorites',
    description: 'At Seez, you can save the car models you are interested in under favorites. Return easily and quickly to the right car for you here'
  },
  Account: {
    path: 'account',
    title: 'My account',
    description: 'Log in to your Seez account and see your search history, saved favorites and order history. Find all your information here'
  },
  Tradeins: {
    path: 'tradeins',
    title: 'My Trade-ins',
    description: ''
  },
  Orders: {
    path: 'orders',
    title: 'My order - Delivery status and order history',
    description: 'Track your order, check delivery status and follow the car loan or barter application process on your Seez account.'
  },
  Details: {
    path: 'listing',
  },
  Intro: {
    path: 'start'
  },
  Why: {
    path: 'why-seez',
    title: 'Why Seez? Easy and convenient car purchase online',
    description: 'Seez is an online dealer of used premium cars. You always get a 1-year full warranty on your new car and the option of a test drive for 14 days.'
  },
  WhySeez: {
    path: 'why-seez',
    title: 'Why Seez? Easy and convenient car purchase online',
    description: 'Seez is an online dealer of used premium cars. You always get a 1-year full warranty on your new car and the option of a test drive for 14 days.'
  },
  Quality: {
    path: 'quality'
  },
  Delivery: {
    path: 'delivery'
  },
  Return: {
    path: 'return'
  },
  Warranty: {
    path: 'warranty'
  },
  About: {
    path: 'about-seez'
  },
}

export const FAVORITES_VIEW_TRANSLATIONS = {
  my_favorites: 'My favorites'
}

export const ERROR_VIEW_TRANSLATIONS = {
  error: 'Error 404',
  page_not_found: 'Page not found'
}

export const FINANCING_VIEW_TRANSLATIONS = {
  finance_your_car: 'Finance your Car',
  spread_the_cost: ['Spread the cost of your car over 12 to 96 months, with flexible deposits'],
  check_budget: 'Check what suits your budget',
  apply_get_started: 'You can apply for finance once you\'ve chosen your car and selected \'Get Started\'. Here\'s how it works.',
  get_quote: 'Get a quote',
  use_our_calculator: 'Use our calculator to find a finance plan that suits you. Deposit from as little as 0 DKK or use your part-exchange as deposit',
  finance_partner: 'Our financing partner',
  get_clearer_picture: 'Get a clearer picture of what can afford',
  use_loan_calculator: 'Use our loan calculator to get an estimate of your monthly payments with different down payments.',
  ready_get_finance: 'Ready to get car finance?',
  pay_with_finance: 'When you\'ve chosen your new car, select \'Pay with finance\' in checkout.',
  search_all_cars: 'Search all cars',
  cars: 'cars',
  how_it_works: 'How it works',
  apply_online: 'Apply online',
  choose_the_terms: 'Choose the terms you want and fill out a simple loan application as you checkout your car.',
  get_a_decision: 'Get a decision',
  receive_a_decision: 'Receive a decision within 24 hours of applying - less if you apply during regular working hours.',
  popularQuestions: {
    title: 'Popular questions about Financing',
    accordions: [
      {
        id: 'aboutSeez',
        title: 'What is Seez?',
        text: [`Seez is a new way to buy an almost new car entirely online. Our platform allows you to search through a multitude of cars from dealerships across the country, purchase the one you like, apply for financing, trade in your old car, and sign all contracts from the comfort of your home. 
        We've turned car buying into an easy, haggle-free, minute-long process. And on top of it all, your car is delivered straight to your door and you get 14 days to decide if you want to keep the car or return it, no questions asked.`]
      },
      {
        id: 'accidentedCars',
        title:
          'Do you sell cars that have been in an accident?',
        text: [`No. We've set the highest standards on the cars we categorize as almost new - standards that cannot be met by cars that have been reported in accidents.   
        Any car that has been in anything from a fender-bender to being officially categorized as a write-off, is automatically rejected by our Selection Experts and will not be listed on our platform.`]
      },
      {
        id: 'isPriceNegotiable',
        title: 'Are prices negotiable?',
        text: [`When you buy a car from Seez, you also buy the knowledge that we've done the negotiating for you. While our prices are non-negotiable, we do promise that our cars are listed at the best possible price you could get.
        Besides, isn't the best part about buying online having the ability to purchase something quickly, efficiently, and with absolutely no haggling?`]
      },
      {
        id: 'testDrive',
        title: 'Can I test drive the car?',
        text: [`Test drives are a thing of the past. Taking a car for a 30 minute spin is an inefficient way to test it. Instead, we offer you 14 days to take the car home and take your time assessing it in any way you think is best - be it driving it on different terrains, in varying weather conditions, or simply squeezing your entire family into it and driving around the block. 
        As long as the car is undamaged, unaltered, and hasn't been driven more than 500 km, we'll take it back and give you a full refund should you choose to return it within the 14 day limit.`]
      }
    ]
  }
}

export const HOME_VIEW_TRANSLATIONS = {
  buy_your_almost_new_car: 'Buy hand-picked and quality-tested cars online from Denmark\'s most trusted dealers',
  with_complete_confidence: 'Delivered free to your door with a 14 days test drive',
  make: 'Make',
  model: 'Model',
  search: 'Search',
  search_all_cars: 'Search all cars',
  inspire_me: 'Inspire me',
  shop_by_car_type: 'Shop by car type',
  bought_a_car_from_seez: 'Have you bought a car from Seez?',
  tell_us_what_you_think: 'Tell us what you think',
  our_certified_dealers: 'Our Premium Brands',
  browse_by_lifestyle: 'Browse by lifestyle',
  unsure_what_you_are_looking_for: 'Unsure what you\'re looking for?',
  find_inspiration_here: 'Find inspiration here.',
  see_all: 'See all',
  budget: 'Budget Conscious',
  family_life: 'Family Friendly',
  outdoor_adventure: 'Outdoor Adventure',
  single: 'Luxury Living',
  car_financing: 'Car Financing',
  insurance: 'Insurance',
  finance_calculator: 'Finance calculator',
  get_a_car_loan_easily: 'We\'ve made getting a car loan as easy and efficient as possible. With Seez, you can now apply entirely online and get the best interest rates within 24 hours',
  learn_more: 'Learn more',
  trade_in_valuation: 'Trade In Valuation',
  have_a_car_to_trade_in: 'Have a car to trade in?',
  plug_car_get_best_offer: 'Plug in your car\'s details and get the best offer possible within minutes. Stress-free, fully binding, and with no hidden fees',
  little_extra_for_peace_of_mind: 'A Little Extra for Some Peace of Mind',
  car_care: 'Car care',
  want_to_give_car_the_love: 'Want to give your new car the love it deserves?',
  car_care_gives_confidence: 'Car Care gives you everything you need to keep your almost new car almost new for as long as possible. Don\'t just buy with confidence, drive with confidence',
  servicing: 'Servicing',
  time_for_car_maintenance: 'Time for your car\'s annual maintenance run?',
  service_center: 'Get your almost new car serviced at our servicing centers and let us pamper the both of you. Sit back, sip on your coffee, &amp; let our experts keep your car the same perfect bundle of joy that you bought',
  warranty: 'Warranty',
  one_year_not_enough: 'One year not enough?',
  extend_warranty: 'Extend your warranty at purchase to a full lifetime. Never worry again, we\'ve got you covered',
  recently_added_cars: 'Recently added cars',
  browse_all_vehicles: 'Browse all vehicles',
  finance_with_seez: 'Finance with Seez',
  trade_with_seez: 'Trade with Seez',
  our_customer_reviews: 'Our Customers reviews',
  finance_provided_by: 'Financing provided by',
  shop_electric_cars: 'Shop electric cars',
  shop_sedan_cars: 'Shop sedan cars',
  shop_hatchback_cars: 'Shop hatchback cars',
  shop_suv_cars: 'Shop SUV cars',
  shop_wagon_cars: 'Shop wagon cars',
  tryg_partnership_paragraph_one: 'Take good care of yourself and your loved ones',
  tryg_partnership_paragraph_two: 'To give you peace of mind when you hit the roads in your new car, we have started a partnership with the Nordics\' largest non-life insurance company, Tryg',
  tryg_partnership_paragraph_three: 'Full customer: When you have contents, accident, travel and house or car insurance with Tryg',
  tryg_partnership_description_row_one: 'You get a 20% discount on your car insurance',
  tryg_partnership_description_row_tow: 'An additional 10% discount on your car insurance in the first year if you become a full customer* of Tryg',
  tryg_partnership_description_row_three: 'Possibility of 5-8% bonus from TryghedsGruppen',
  cars: 'cars',
  select_make: 'Select make',
  select_model: 'Select model',
  life_styles: {
    electric: {
      title: 'Electric cars',
      description: 'Electric options for you who think about the environment.',
      tag: { label: 'Environment' }
    },
    family: {
      title: 'Family',
      description: 'The perfect family car for your family\'s needs.',
      tag: { label: 'Lifesytle' }
    },
    budget: {
      title: 'Budget',
      description: 'Economy cars at reasonable prices.',
      tag: { label: 'Budget friendly' }
    },
    city_cars: {
      title: 'City cars',
      description: 'Cars for you who live or work in the city.',
      tag: { label: 'Parking friendly' }
    }
  },
  leave_review_button_text: 'Leave us a review'
}

export const MODAL_UNAVAILABLE_CARS_TRANSLATIONS = {
  car_is_not_available: 'This car isn\'t available anymore',
  we_got_similar_cars: 'We\'ve got similar cars for you to choose from.',
  back_home: 'Back to Home',
  view_similar: 'View similar cars',
  we_will_notify_you: 'We will notify you when we have a new similar car or if this car becomes available again. ',
  browse_all: 'Browse all',
}

export const JOURNEY_INTRO_VIEW_TRANSLATIONS = {
  welcome: 'Welcome',
  you_found_perfect_car:
    'You’ve found your perfect car and you’re ready to go ahead',
  during_your_checkout: 'During your checkout',
  you_fill_personal_details: 'You fill in your personal details',
  you_add_trade_in:
    'You add a trade-in, if you have one, by adding some details and photos',
  you_decide_how_apply_finance:
    'You decide how you want to pay and apply for financing, if needed',
  you_add_your_date: 'You add in your delivery address and preferred date',
  your_car_is_reserved: 'Your car is reserved',
  what_happens_next: 'What happens next?',
  trade_in: 'Trade in',
  receive_offer_one_day:
    'You receive an offer within one working day (if applicable) and choose to accept or reject it',
  financing: 'Financing',
  you_receive_decision:
    'You receive a financing decision from Santander (if applicable)',
  sales_agreement: 'Sales Agreement',
  we_send_you_contract: 'We send you the contract for signature',
  payment: 'Payment',
  you_transfer_your_payment: 'You transfer your payment',
  delivery: 'Delivery',
  we_deliver_your_car:
    'We deliver your car to your home and pick up your trade-in if you added one',
  things_to_note: 'Things to note',
  you_free_of_charge:
    'You are free to change your mind and cancel the order, completely free and at the click of a button, even after confirming.',
  you_will_not_be_expected_payment:
    'You won’t be expected to make any payments until you’ve signed the contract.',
  after_delivery: 'After Delivery',
  you_have_fourteen_days:
    'You have 14 days to test the car and decide if you want to keep it.',
  start_checkout: 'Start Checkout',
  here_when_you_need_us: 'Here when you need us',
  if_you_need_assistance:
    'If at any point within the journey you need assistance, reach out to us via the live chat pop-up or leave your number and we’ll give you a call during regular working hours.',
  fourteen_days_return: '14 dages fuld returret',
  full_transparency: 'i tror på fuld gennemsigtighed.',
  fourteen_days_test_drive:
    'Du får hele 14 dage til at prøvekøre din nye bil. Hvis du ikke er tilfreds med bilen, sender du den bare tilbage til os - og vi stiller ingen spørgsmål.',
  one_year_guarantee: '1 års garanti',
  we_believe_in_peace_of_mind: 'Vi tror på "ro i sindet".',
  no_extra_cost_guarantee:
    'Du får 1 års fuld garanti på din nye bil. Ingen ekstra omkostninger.',
  quality_assured: 'Kvalitetssikret',
  we_believe_almost_new: 'Vi tror på værdien i "næsten nyt".',
  our_cars_are_certified:
    'Vores udvalg af biler kommer udelukkende fra betroede premium partnere. Alle bilerne er certificerede og gennemtestede.',
  free_delivery: 'Fri levering',
  total_convinience: 'Vi tror på total bekvemmelighed.',
  your_car_delivered_to_door:
    'Du behøver ikke længere en bil for at købe en ny bil. Din bil bliver leveret direkte til døren.',
  total_to_pay: 'Total to pay',
  car_is_not_available: 'This car isn\'t available anymore',
  we_got_similar_cars: 'We\'ve got similar cars for you to choose from.',
  back_home: 'Back to Home',
  view_similar: 'View similar cars',
}

export const BENEFITS_COMPONENT_TRANSLATIONS = {
  quality_assurance: 'High quality',
  our_selection_of_cars: 'All our cars go thorough quality assurance and meet high quality requirements. Our selection of cars comes exclusively from Denmark\'s most trusted premium dealers',
  fourteen_day_return: '14 days full right of return',
  you_get_fourteen_days_test_drive: 'You have 14 days from delivery to test drive your car and ensure it suits your needs. If you are not satisfied with the car, you can return it at no extra cost',
  one_year_warranty: '1 year warranty',
  your_car_goes_home_with_full_guaranty: 'We guarantee all the cars we sell, which is why you get a 1-year full warranty on the car with the purchase',
  free_delivery: 'Free delivery',
  you_do_not_need_car_to_buy_one: 'To make it as easy as possible, you get the car delivered right to your door, free of charge',
  why_buy_a_car_from_seez: 'Why buy a car from Seez?'
}

export const HEADER_COMPONENT_TRANSLATIONS = {
  browse: 'Browse',
  trade_in: 'Trade-In',
  financing: 'Financing',
  why_seez: 'Why Seez',
  support: 'Support',
  login: 'Login',
  hello_user_name: 'Hello',
  my_searches: 'My Searches',
  my_favorites: 'My Favorites',
  my_tradeins: 'Trade-ins',
  my_orders: 'Orders',
  account_settings: 'Account Settings',
  logout: 'Logout',
  why_buy_from_seez: 'Why buy from Seez',
  our_aim: 'At Seez, our aim is to unlock a work where you can buy a car, sell your old one, and get financing and insurance with full confidence - all from the comfort of your home.',
  read_more_about_seez: 'Read More about Seez',
  how_it_works: 'How it works',
  seez_benefits: 'Seez Benefits',
  quality_asured_title: 'Quality Assured from trusted partners',
  quality_asured_text: 'Our range of cars comes exclusively from trusted premium partners.',
  return_title: 'Money-back Guarantee',
  return_text: 'You get a full 14 days to test drive your new car. If you are not satisfied with the car, just send it back to us - and we will not ask any questions.',
  warranty_title: '1 Year Warranty',
  warranty_text: 'You get a 1 year full warranty on your new car. No extra cost.',
  free_delivery_title: 'Free Delivery',
  free_delivery_text: 'You no longer need a car to buy a new car. Your car will be delivered directly to your door.',
  contact_us: 'Contact us',
  seez_dealer_title: 'Cooperate with Seez as a reseller',
  seez_dealer_text: 'Expand to business with our SaaS platform. Become part of our reseller program and get access to powerful tools that can expand your market and increase your sales',
  seez_dealer_learn_more: 'Learn more',
}

export const LOGIN_COMPONENT_TRANSLATIONS = {
  login: 'Welcome!',
  please_login: 'Verify your email to save your favorite searches and access your account details.',
  email_address: 'email address',
  accept_eula: 'I certify that I have read and agreed to Seez’s {tu} and {pp}.',
  terms_use: 'Terms of Use',
  privacy_policy: 'Privacy Policy',
  please_verify_code: 'Please enter the verification code sent to your email',
  resend_otp: 'Resend code',
  get_otp: 'Get OTP',
  coming_soon: 'Coming Soon',
  confirm: 'Confirm',
  go_back: 'Go back',
  continue_with_google: 'Continue with Google',
  continue_with_facebook: 'Continue with Facebook',
  marketing_terms:
    'Yes, I want Seez Aps to keep me updated on products relevant to my car and products within their range, including reminders about items in my online shopping cart, via letter, phone and electronic mail (email and SMS). Seez ApS may also contact me with a view to extending my consent to include other products and forms of contact. I accept Seez\'s storage and processing of my data as described in the privacy policy, and that I can always revoke my consent by contacting Seez by e-mail: support@seez.dk'
}

export const FOOTER_COMPONENT_TRANSLATIONS = {
  all_rights_reserved: 'All Rights Reserved',
  about_us: 'About us',
  about_financing: 'About financing',
  about_trade_in: 'About trade-in',
  careers: 'Careers',
  support: 'Support',
  report_bug: 'Report a Bug',
  become_a_dealer: 'Become a Dealer',
  manage_subscriptions: 'Manage Subscriptions',
  seez: 'seez.co',
  privacy_policy: 'Privacy Policy',
  terms_and_conditions: 'Terms and Conditions',
  cookie_policy: 'Cookie Policy'
}

export const TRADE_VIEW_TRANSLATIONS = {
  about_trade_in: 'Trade it in',
  trading_your_car_is_easy: 'Out with the old, in with the new',
  how_it_works: 'How it works',
  trading_your_car_is_quick: 'Use your old car to help pay for your new car.',
  step_one: 'Step 1',
  fill_in_info: 'Fill in some information about your car',
  step_two: 'Step 2',
  get_offer_from_us: 'Get an offer from us within 1 working hour',
  step_three: 'Step 3',
  accept_have_the_full_amount:
    'Have the amount deducted from the price of your new car',
  why_trade_with_seez: 'Why Trade with Seez',
  quick_and_easy_process: 'Quick and easy process',
  we_will_only_ask_necessary: 'We’ll only ask what is absolutely necessary',
  no_extra_hidden_fees: 'No hidden fees',
  we_deduct_offer_amount:
    'We’ll deduct the exact amount offered from the total price of your new car',
  haggle_free: 'Haggle-free, honest estimates',
  we_give_you_best_offer: 'We’ll always give you our very best offer',
  easy_hand_over: 'Easy hand-over',
  we_pick_up_your_trade_in:
    'We’ll pick up your trade-in when we deliver your new car',
  what_to_expect_on_the_day: 'What to Expect on the Day',
  we_drop_off_your_new_car: 'Your almost new car is delivered',
  at_your_door:
    'For maximum convenience, we drop off your car to your chosen location.',
  our_expert_gives_your_a_quick: 'Your trade-in is given a quick check',
  inspection: 'Our expert gives your trade-in a fast, but thorough inspection.',
  we_take_it_from_there: 'We take it from there',
  we_exchange_keys:
    'We exchange keys and documents, and leave you to enjoy your awesome new car.',
  recently_added_cars: 'Recently added cars',
  browse_all_vehicles: 'Browse all vehicles',
  trade_in_hero_list_one: 'Guaranteed price for your car',
  trade_in_hero_list_two: 'No hidden fees',
  trade_in_hero_list_three: 'Easy online process',
  trading_with_seez_section_title: 'Trading your car with Seez',
  trading_with_seez_section_list_item_one: 'Give us some details about your trade-in car and get an offer from us within 2 working hours.',
  trading_with_seez_section_list_item_two: 'When you’re happy with your trade-in offer, buy your new car online with {{free delivery}}.',
  trading_with_seez_section_list_item_three: 'Our {{14-day money back guarantee}} allows you to test drive it and make sure it fits your lifestyle.',
  trading_with_seez_section_list_item_four: 'We’ll pick up your trade-in when we deliver your new car.',
  submit_request_section_title: 'Where do you submit a trade-in request?',
  submit_request_section_title_caption: 'Follow these 3 simple steps',
  submit_request_section_button_text: 'Start here',
  submit_request_section_steps_one: 'Fill in information about your car',
  submit_request_section_steps_two: 'Get an offer from us within an hour',
  submit_request_section_steps_three: 'Get the amount offset against the price of your new car',
  what_happens_section_title: 'What happens on the day?',
  what_happens_section_list_item_one_text: 'We inspect your car',
  what_happens_section_list_item_one_additional_text: 'Our handover specialist will check your car is roadworthy and matches the details you gave.',
  what_happens_section_list_item_two_text: 'We collect your paperwork',
  what_happens_section_list_item_two_additional_text: 'Be sure to have your spare keys and any service history handy for us.',
  what_happens_section_list_item_three_text: 'We take it from there',
  what_happens_section_list_item_three_additional_text: 'We take your old car with us when we deliver your new car.',
  documentation_section_title: 'Some Necessary Documents',
  documentation_section_list_item_one_text: 'The trade-in\'s registration documents',
  documentation_section_list_item_two_text: 'The service book',
  documentation_section_list_item_three_text: 'At least one set of keys',
  documentation_section_list_item_four_text: 'The car manual - if applicable',
  needUsText: [
    'If you have any questions about the exchange process, you are very welcome to contact us.',
    'We are always ready to answer any questions you may have.'
  ],
  popularQuestions: {
    title: 'Frequently asked questions about Trade-in cars',
    accordions: [
      {
        id: 'cartradein',
        title: 'How do I know I\'m getting a good price for my trade-in car?',
        text: [
          'At Seez, we make use of our large network of partners when we need to find the price of your trade-in car. In this way, you are always guaranteed a competitive and fair trade-in price.'
        ]
      },
      {
        id: 'carrating',
        title: 'How do you calculate your rating on my car?',
        text: [
          'Our cars are as close to new as possible. But if they have scratches, dents or the like, it will be highlighted and clear in the pictures.',
          'At Seez, your car is assessed based on several factors, including: Make and model, some car brands and models are more popular than others, which of course has an impact on the resale value.',
          'The car\'s age, condition and mileage. Depending on how old the car is and how many kilometers it has driven, we set a price - but if 2 cars are the same age and have driven the same, then there can easily be a difference in condition and this also matters for an assessment.',
          'But we always assess based on a real market situation, so you are always guaranteed the best market price.'
        ]
      },
      {
        id: 'carcondition',
        title: 'What if the car is not in the condition I expected?',
        text: [
          'We maintain the highest level of honesty and transparency. We do everything we can to ensure that the condition of the car is correctly and accurately described.',
          'But if you are unlucky enough to receive a car from us that is not in accordance with the expected condition, we will find out how to get it repaired - or you return the car within 14 days according to the applicable regulations.'
        ]
      },
      {
        id: 'carloan',
        title: 'Will Seez buy my trade-in car even though I still owe money on it?',
        text: [
          'You can easily sell your trade-in car to Seez, even if there is a residual debt on it.',
          'The remaining debt must simply be able to be paid off, either by the fact that the trade-in car is worth more than the outstanding debt or by you yourself ensuring that the remaining debt is paid off before delivery.'
        ]
      }
    ]
  }
}

export const WHY_SEEZ_VIEW_TRANSLATIONS = {
  why_seez: 'Why Seez',
  buy_a_car_msg: 'Car buying made truthful',
  why_is_hard_to_do: 'So why is it so hard to do?',
  about_us: 'A Little Bit About Us',
  at_our_core: 'At our core, we at Seez are just a bunch of tech geeks and car enthusiasts - constantly debating which half of us is more important for the success of this platform.',
  what_is_non_debatable: 'What is absolutely non-debatable is that all of us sincerely want to create a seamless online car retail experience for you.',
  what_make_us_different: 'What makes us Different?',
  we_understand: 'We Understand',
  we_have_all_been_there_msg: 'We’ve all been there. We’ve all had to go out and buy a car. We know how confusing and stressful it can be - all the decisions involved.',
  you_never_question_our_loyalties: 'You never have to question our loyalties.',
  we_care: 'We Care',
  we_have_our_top_priority: 'We’ve made you our top priority. Our entire business is built around giving you all the information available so you can make a decision you are happy with.',
  you_never_have_to_hanggle: 'You never have to haggle for the best price again.',
  we_commit: 'We Commit',
  we_work_with_partners_who_shares_our_values: 'We only work with partners who share our values. We bring you the best cars on the market, inspect them ourselves, and offer you a 1-year warranty.',
  you_dont_have_to_worry: 'You don’t have to worry about quality ever again.',
  we_empower: 'We Empower',
  we_are_here_for_you: 'We’re here for you. Our journey doesn’t just end when we deliver the car. Our agents are always available to help with all of your car needs.',
  you_dont_have_to_go: 'You don’t have to go through this alone anymore.',
  we_made_our_mission_to_empower: 'We, at Seez, have made it our mission to empower you to buy your next car with confidence. We want to arm you with everything you need to take away any doubt and bring back the thrill of buying a new car.',
  browse_cars: 'Browse Cars Now',
  seez_benefits: 'Seez benefits you get with any car'
}

export const MODAL_ACTIVE_ORDER_COMPONENT = {
  active_order_change: 'Active Order Change',
  you_have_active_order: 'You already have an active order in progress!',
  are_you_sure_cancel:
    'Are you sure you want to cancel your current order and start a new one?',
  are_you_sure_cancel_active_order:
    'Are you sure you want to cancel your current order and start a new one?',
  price: 'Price',
  cancel_start_new_order: 'Cancel and Start New Order',
  resume_order: 'Resume Existing Order'
}

export const MODAL_EMAIL_ERROR_COMPONENT = {
  incorrect_email: 'Incorrect Email',
  wrong_email_message: 'The email you logged in with is not associated with this order. Try logging in with a different one.',
  logout: 'Logout',
  browse_similars: 'Browse similar cars'
}

export const MODAL_SEEZAR_INFO = {
  meet_seezar: 'Meet Seezar',
  with_seezar: 'With Seezar, you can receive 24/7 support for your inquiries. It retains your previous questions and tailors its responses to your preferences.',
  try_now: 'Try it now'
}

export const POPOVER_SEEZAR_INFO = {
  meet_our_new: 'Meet our new',
  chatbot: 'chatbot',
  click_here: 'Click on this tab to start chatting'
}


export const CHECKOUT_COMPONENT_TRANSLATIONS = {
  user_not_found: 'User not found'
}

export const PROFILE_VIEW_TRANSLATIONS = {
  my_account: 'My Account',
  account_settings: 'Account Settings',
  my_favorites: 'Favorite Cars',
  my_tradeins: 'Trade-ins',
  my_orders: 'Orders',
  my_searches: 'Saved Searches',
  my_trade_ins: 'My Trade-ins',
  logout: 'Log out'
}

export const SEARCHES_VIEW_TRANSLATIONS = {
  saved_searches: 'Saved Searches'
}

export const META_SEO_CONTENT = {
  default: { title: 'Used {brand}', description: 'Used {brand} for sale » Large selection of used cars', }
}

export const TERMS_OF_USE = {
  title: 'Terms and Conditions',
  full_text: `      <ol>
        <li>
          These terms
          <ol>
            <li>These are the terms and conditions (“Terms”) that apply when Customers (“Customer” or “You”) use our website <a href="https://www.seez.dk">www.seez.dk</a> (“Site”), which provides access to the Seez Marketplace (“Marketplace") to purchase cars from our dealer partners (“Dealerships”). These Terms may change from time to time, so make sure you check these Terms each time you buy a car (the “Car”) via Seez.</li>
            <li>Please make sure you read these Terms carefully before you are buying your car. We suggest that you keep a copy of these Terms for future reference.</li>
            <li>By agreeing to these Terms you have accepted to receive the Terms, the Agreement (as defined below) and any and all information related to the sale of the Car electronically in a readable format, such as PDF.</li>
          </ol>
        </li>
        <li>
          Who are we?
          <ol>
            <li>The Site is operated by Seez ApS (either “We”, “Us” or ”“Seez”) a company registered in Denmark with company registration number (CVR) 42847682, with registered address Højbro Plads 10, 1200 Copenhagen.</li>
            <li>If you would like to get in touch or have any questions about the Car or the service provided by Seez please contact us. You can find our contact details here <a href="https://support.seez.dk/">https://support.seez.dk/</a>.</li>
          </ol>
        </li>
        <li>
          Seez Aps role in relation to the agreement between you and the Partners
          <ol>
            <li>Seez provides the Marketplace for the Customer to facilitate the purchase of Cars and other services, customer service and delivery of the goods and services offered on the Marketplace.</li>
            <li>Dealerships, financial partners, insurance agencies and any other vendor we work with are collectively referred to as Partners (“Partners”) in these Terms.</li>
            <li>You may not buy a car via us if you are doing so in the course of business or the car is not intended for personal use only.</li>
            <li>You must also be resident in Denmark to purchase a Car through the Marketplace.</li>
            <li>
              After confirming your Order on the Seez Marketplace (the “Order Confirmation”), a legally binding agreement (“Agreement”) between you and the respective Dealership will be generated:
              <ol>
                <li>the Agreement will be based on the details specified in the Order;</li>
                <li>the Agreement needs to be signed by both parties prior to the transfer of ownership being initiated by the Dealership;</li>
                <li>If there is any inconsistency between the Agreement and these Terms, then the Agreement will take priority.</li>
              </ol>
            </li>
            <li>If you choose to pay for the Car using a Finance Agreement with a Lender (as defined in section 9 below) then you will be required to enter into an agreement with the Lender, and the respective dealership will transfer the ownership in accordance with the Agreement between the Customer and the Lender. Please refer to <a href="#sectionFinance">section 9</a> for more details.</li>
            <li>The Dealerships are independent of Seez. Seez is thus not responsible for any disputes or errors in the Agreement between Customer and Dealership.</li>
            <li>
              Occasionally, we may become aware after accepting your order that we are unable to proceed with the Agreement, in which case we will notify you by email. Some examples of reasons why we may do this include:
              <ol>
                <li>the Car you have chosen has already been sold;</li>
                <li>there is an error in the price or description of the Car; or</li>
                <li>You should be aware that there may be reasons other than those reasons mentioned above why we do not accept your Order.</li>
              </ol>
            </li>
            <li>For the sake of clarity, Seez will retain a copy of both the Order and the Agreement in its database.</li>
          </ol>
        </li>
        <li>
          Your Car
          <ol>
            <li>The Car may have been used previously for lease or rental and may have had multiple users. It may also have been imported from Germany or other countries that meet Danish regulations. All Cars on the Marketplace are no more than 4 years old and have mileage less than 100,000 km on their odometer. The Cars have gone through a series of tests and checks and been proven clean, reliable, and safe.</li>
            <li>Each Car undergoes a thorough inspection by the respective Dealership providing the Car. Please always refer to the Agreement made with the Dealership for an accurate description.</li>
            <li>We make every effort to display the Car accurately, including damage (if provided), but we cannot guarantee that the pictures of the Car on the Marketplace show every part of the Car or colour of the Car. The images of Cars on the Marketplace are provided by the Dealerships. If you are not satisfied with the actual appearance of the Car you may cancel the purchase using our 14-day money back guarantee (see section 10 for more information).</li>
            <li>The mileage of a Car stated on the Marketplace may vary slightly to the actual mileage of the Car delivered. The difference in mileage will be no more than 200 kilometres.</li>
            <li>You may contact us before placing the Order to ask any questions you have about the Car. You can find our contact details here <a href="https://support.seez.dk/">https://support.seez.dk/</a>.</li>
          </ol>
        </li>
        <li>
          Delivery
          <ol>
            <li>We will deliver the Car to you at the delivery location on the delivery date set out in the Order Confirmation (“Delivery”).</li>
            <li>Seez delivers to addresses in Denmark (“Jutland”, “Funen”, “Zealand” and “Bridged Islands”). If the address for Delivery is located on a Non-Bridged Island or the location is exceptionally difficult to reach due to certain types of weather or road conditions of the year, Seez retains the right to refuse Delivery.</li>
            <li>You may change the time and date of Delivery by emailing our customer service team at support@seez.dk at least 24 hours before your agreed delivery date. We will rearrange the date and time of your Delivery at no extra cost to you.</li>
            <li>You may also cancel the Order without any reason up until 24 hours before the Car is scheduled for delivery by emailing our customer support team at support@seez.dk.</li>
            <li>We will only deliver the Car to you at the delivery location stated in the Order. Upon Delivery, you must provide us with a valid ID (Danish driving license or passport copy).</li>
            <li>We will need a safe place to park the Car (for example, a driveway or safe on-road parking) whilst we complete our delivery checks with you. If you are selling a Trade-In Car to us, then you should also make sure that it is parked safely and is accessible by us. You are responsible for giving us details of a safe location and will be responsible for any costs, penalties or fines we incur as a result of parking in the safe location provided by you.</li>
            <li>
              You will own the Car once
              <ol>
                <li>you have accepted Delivery of the Car by signing the delivery note (the “Delivery Note”); and</li>
                <li>we have accepted and received the Trade-In Car in the case you choose to use a Trade-In Car as partial payment (please see section 8 for more information) and you have signed the trade-in handover note (the “Trade-in Handover Note”); and</li>
                <li>the dealership has received full payment of the Payment Due and any other money you owe to us; and</li>
                <li>if you are purchasing the Car using a Finance Agreement, then the third-party finance provider will own the Car until such time as the ownership is transferred as per the Finance agreement.</li>
              </ol>
            </li>
            <li>You are responsible for the Car from Delivery.</li>
          </ol>
        </li>
        <li>
          Payment
          <ol>
            <li>The Payment Due on the Car is detailed in your Order Confirmation which is received when your Order is finalized on the Marketplace.</li>
            <li>The Order Confirmation includes applicable taxation charges. Environmental tax and/or weight tax that is not due is not included in the price.</li>
            <li>The Payment Due can only be done by bank transfer to the bank information provided. At the moment, we do not accept any other form of payment.</li>
            <li>
              If the Order Confirmation details a payment due to you (when the trade-in value offered exceed the down payment amount) please contact our customer support department at support@seez.dk to discuss your options; you can either
              <ol>
                <li>adjust the down payment to match the value of your trade-in, or</li>
                <li>arrange for a repayment of the balance due.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Warranty
          <ol>
            <li>The warranty on your new car is provided by the Dealership. At a minimum, your Car will include a 1-year warranty. But depending on the Car, it may be longer. The warranty is a “bumper to bumper dealer” warranty.</li>
          </ol>
        </li>
        <li>
          Trade-In
          <ol>
            <li>Where you choose to use a Trade-In Car as partial payment, you must provide details of the Trade-In Car on the Seez Marketplace as part of your Order. We may ask you for additional information about the Trade-In Car at any time prior to Delivery.</li>
            <li>We will give you a valuation for the Trade-In Car when you confirm your Order on the Seez Marketplace. You must provide true, accurate and up-to-date information to us about the Trade-In Car.</li>
            <li>The Trade-In Car will be inspected on Delivery of your new Car to ensure the information provided when you placed your Order was true and accurate. We will also need to ensure that the Trade-In Car is roadworthy and has not sustained any damage you have not disclosed to us.</li>
            <li>
              Where the Trade-In Car is subject to a Finance Agreement, we will accept your car even if you still owe finance on the car. You can;
              <ol>
                <li>settle your outstanding finance bill before we collect your car, or</li>
                <li>inform us of the exact remaining balance while placing your Order, on the Trade-in section, and we'll add that to the Payment Due and we will coordinate to settle the outstanding balance with the Lender on your behalf.</li>
                <li>If the car is still being financed and the outstanding balance is more than the value of the Trade-in Car, then we will not accept the Trade-in car.</li>
              </ol>
            </li>
            <li>
              At Delivery, in relation to the Trade-In Car, you must give us:
              <ol>
                <li>all copies of the Trade-In Car's keys;</li>
                <li>the Trade-In Car's most recent registration certificate and proof of payment of taxes and charges (together with appropriate proof of purchase if requested by us);</li>
                <li>the Trade-In Car's service history (if any);</li>
                <li>the Trade-In Car's user manuals (if any);</li>
                <li>any accessories there may be such as a locking wheel nut, radio fascia, remote controls, SD card and/or charging cables (if you have a plug-in car); and</li>
                <li>if the Trade-in car is still being financed, you will need to provide us with have a proof of the outstanding amount on the Trade-in Car.</li>
              </ol>
            </li>
            <li>
              We may reject or re-evaluate the offer submitted on a Trade-In Car at any time, up to 14 days after the date of collection if:
              <ol>
                <li>any information given to us about the Trade-In Car is not true and accurate;</li>
                <li>the Trade-In Car has sustained damage that is not disclosed to us when you placed the Order;</li>
                <li>there is a material discrepancy in the information and documents supplied by you in respect of the Trade-In Car or a material document is missing, inaccurate or incomplete (for example, the registration certificate is missing or incomplete);</li>
                <li>the Trade-In Car has any fault which may affect the value of the Trade-In Car or is not roadworthy;</li>
                <li>the Trade-In Car has been in a major accident or has been subject to a total loss claim;</li>
                <li>the Trade-In Car has been modified for appearance or performance purposes;</li>
                <li>you are not legally capable of entering into a binding contract to sell the Trade-In Car;</li>
                <li>you are not the sole or co-owner of the Trade-In Car;</li>
                <li>you have failed to disclose if another person or entity has any claim to the Trade-In Car or there is outstanding finance on the Trade-In Car;</li>
                <li>you are not named as the registered keeper of the Trade-In Car on the registration document;</li>
                <li>the Trade-In Car is not registered in Denmark;</li>
                <li>the Trade-In Car does not bear its legal registration and chassis number;</li>
                <li>the mileage of the Trade-In Car is more than 1,500 kilometres above the mileage stated in the Order; or</li>
                <li>The Trade-in is stolen or reported to the Law Enforcement Authorities</li>
              </ol>
            </li>
            <li>We refer to 8.5 and 8.6 as the Trade-In Conditions.</li>
            <li>Any environmental tax and/or weight due on the Trade-In Car is paid by the Customer until Seez has accepted the Trade-In.</li>
            <li>
              If the Trade-In does not meet the Trade-In Conditions, then;
              <ol>
                <li>you can choose to proceed with the Order subject to a revised valuation of the Trade-in Car. You will be asked to sign a contract amendment on Delivery accepting the additional payment that will be due based on the revised valuation. We will email you within 7 days the revised valuation.</li>
                <li>you can choose to proceed with the Order without a Trade-In. In this case the delivery of your new Car will not take place, until the revised Order has been completed and any additional payments due are made. If subsequently you choose to cancel the Order, it will be subject to a 2,500 DKK delivery fee.</li>
                <li>you can choose to cancel the Order, subject to a 2,500 DKK delivery fee, and Delivery of your new Car will not take place.</li>
              </ol>
            </li>
            <li>If we accept the Trade-In Car at Delivery and discover later that the Trade-In Car did not meet the requirements set out in this section, we may take to recover the reduction in value of the Trade-In Car as a result of the Trade-In Car not meeting the requirements in this section (Recovery Claim). You may also have to pay to us on demand all reasonable costs and expenses incurred by us in pursuing a Recovery Claim.</li>
            <li>You are liable for the condition of the Trade-In Car until we have accepted the car as a Trade-In at the Delivery. In the event of any damage that may occur during this period, the Customer is liable and we will be entitled to renegotiate the exchange price or decline the Trade-In car.</li>
            <li>You must remove any personal possessions from the Trade-In Car before we collect it. We will not be responsible for any personal items lost once the Trade-In Car is in our possession.</li>
            <li>
              You are responsible for removal, deletion or redaction of your personal information contained in the Trade-In Car prior to handover of the Trade-In Car, including as follows:
              <ol>
                <li>If the Trade-In Car has a satnav, phone interface or any other device capable of storing data, you will ensure that you have removed all personal data from these devices prior to handing the Trade-In Car over to us. This includes contacts (names and numbers), previous or favourite destinations in your trip history and default “home” address; and</li>
                <li>you will ensure that your name and address are removed or redacted from any service or maintenance records that you hand over with the Trade-In Car.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li id="sectionFinance">
          Finance
          <ol>
            <li>You may apply for finance from one of our third-party finance providers (Lender) to finance the purchase of the Car.</li>
            <li>Your application for finance is at the sole discretion of the Lender.</li>
            <li>In all situations, a down payment is required, and by default set at a minimum of 20% of the Car Listing Price.</li>
            <li>To obtain finance you must sign a purchase agreement (Finance Agreement) with the Lender prior to Delivery. Any delay in signing the Finance Agreement may delay Delivery.</li>
            <li>If you apply for finance and are declined by the Lender, then you must pay for the Car by an alternative method. If you are unable to do so, then we will cancel your Order and there will be no Agreement.</li>
            <li>You may not apply for finance from a Lender if it is intended that the Car will mainly be used by someone other than you.</li>
            <li>Please refer to the terms and conditions stated in the Finance Agreement concluded between you and the Lender in respect to finance the purchase of the Car.</li>
            <li>
              You have the right to withdraw in line with the terms set out in your Finance Agreement. Please refer to the terms of your Finance Agreement for more information on your right to withdraw.
              <ol>
                <li>If you exercise your right of withdrawal, you will withdraw from the Finance Agreement only. You will be required to pay the amount of credit plus interest to the Lender in accordance with the terms of the Finance Agreement. Please refer to the Lender for more information on the right of withdrawal for the Finance Agreement.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Our 14-day money back guarantee
          <ol>
            <li>You may return the Car to us within 14 days from the time and date of Delivery without giving us any reason using our 14-day money back guarantee (the Guarantee), provided that the Car meets the Return Conditions set out in section 10.5 below.</li>
            <li>You are entitled to use the Car to determine if it is the right fit for you. We consider this use to be similar to a couple of test drives which you would receive if you were buying a Car from a dealer face to face.</li>
            <li>If you wish to return the Car in accordance with the Guarantee, you shall notify us by emailing our customer support team at support@seez.dk. The customer experience team will arrange a date on which we will collect the Car from you at the same address the car was delivered to.</li>
            <li>
              If you choose to return the Car under the Return Period Guarantee:
              <ol>
                <li>if you paid for the Car in full by cash payment through bank transfer, we will repay you the Payment Due to the same bank account originally used to pay for the Car, subject to the terms set out in 12.4.4</li>
                <li>if you paid for the Car using a Finance Agreement, you will be repaid the down payment which you paid to us and subject to the terms set out in 12.4.4; and we will repay the remainder of the Purchase Price to the finance provider with whom you entered into a Finance Agreement;</li>
                <li>if you have sold a Trade-In Car to us in part payment of the Car (including as a down payment for a Finance Agreement), we will not be able to return the Trade-In Car to you. We will include an amount equal to the price we paid to you for Trade-In Car in the amount to be repaid to you (subject to the Trade-In Car meeting the requirements set out in section 10);</li>
                <li>
                  we will withhold DKK 4,000 from the money repaid to you, as described in sections 12.3.1, 12.3.2 and 12.3.3 until:
                  <ol>
                    <li>all the necessary documents to us in accordance with section 12.8 has been returned, and</li>
                    <li>we receive confirmation that the Car is free from all charges or claims from any third party ;</li>
                    <li>we would expect this process to take up to two weeks</li>
                  </ol>
                </li>
                <li>we will repay any money owed to you within 14 days of the date the Car is returned. We will repay you using the same means of payment as you used to pay us and we will not charge you any fees in relation to the repayment.</li>
              </ol>
            </li>
            <li>
              You may return the Car to us using the Guarantee provided that the Car meets the following conditions (“Return Conditions”):
              <ol>
                <li>the Car is not driven for more than 200 kilometres since Delivery;</li>
                <li>no alterations or modifications whatsoever have been made to the Car;</li>
                <li>the Car is in the same condition as it was at Delivery or Collection (except fair wear and tear); and</li>
                <li>the Car is free from all charges or claims from any third party including a finance provider (except any finance arranged through the Finance Agreement); and</li>
                <li>the Car has not been driven since the date you notified us of your desire to return the Car to Us.</li>
                <li>We refer to these conditions as the Return Conditions.</li>
              </ol>
            </li>
            <li>If the Car does not meet the Return Conditions then we may, at our sole discretion, allow you to return the Car to Us, subject to additional fees (Return Fees) to be determined by Us.</li>
            <li>You must tell the finance provider that you wish to withdraw from your Finance Agreement. We will not notify the finance provider for you.</li>
            <li>
              On return of the Car you must give us:
              <ol>
                <li>all copies of the Car's keys;</li>
                <li>the Car registration certificate;</li>
                <li>the outstanding finance proof;</li>
                <li>the Car's service history (if any);</li>
                <li>the Car's user manuals (if any);</li>
                <li>any accessories there may be such as a locking wheel nut, radio fascia or remote controls, SD card and/or charging cables (if you have a plug-in car); and</li>
                <li>any other documents or items which we gave to you at Delivery in relation to the Car.</li>
              </ol>
            </li>
            <li>You are responsible for the removal of your personal belongings or the deletion of personal data from the Car prior to collection in the same manner as for a Trade-In Car (see section 8).</li>
            <li>If you need to rearrange the date and time of your return collection, you must contact the customer experience team at least 24-hours before the day your agreed return collection. You must allow us to collect the Car as soon as possible and at the latest within 7 days of you giving notice to cancel.</li>
            <li>If you return the Car using the Guarantee we will notify the Warranty Provider and the warranty or any other service contract arranged through the Warranty Provider will be automatically terminated.</li>
          </ol>
        </li>
        <li>
          Data Protection
          <ol>
            <li>A summary of how we will use your personal information is set out in our <a href="/privacy">privacy policy</a> which can be found on our Privacy Policy page.</li>
          </ol>
        </li>
        <li>
          Liability
          <ol>
            <li>Nothing in these Terms excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by law.</li>
            <li>To the extent we are able to restrict our responsibility by law, we will not be responsible to you for any indirect or consequential losses or loss of income or revenue, loss of business, loss of profit, or loss of anticipated savings (whether direct or indirect).</li>
            <li>To the extent we are able to exclude our responsibility by law, we exclude all implied representations, warranties, terms and conditions from the Agreement.</li>
            <li>Subject to the above, our maximum total liability to you under or in connection with the Order and the Agreement with you shall not exceed the Purchase Price of the Car.</li>
          </ol>
        </li>
        <li>
          Circumstances outside of our control
          <ol>
            <li>We will not be responsible for any failure by us to perform the Agreement or any delay in performing the Agreement which is caused by a circumstance or event outside of our control (Circumstance Outside of Our Control). Circumstances or events which may be included in this section are riots, acts of terrorism or war, civil disruption, strikes, fire, flood, other natural disasters, pandemics, and accidents relating to the Car. Other circumstances or events may also be included if they are outside of our control.</li>
            <li>If our supply of the Car is delayed by a Circumstance Outside of Our Control, then we will contact you as soon as possible to let you know and we will do what we can to reduce the delay. If we do this, we will not be responsible for delays. If there is a delay you may contact us to end the Agreement and receive a refund of the deposit or the Purchase Price you have paid at that time for the Car.</li>
            <li>If a Circumstance Outside of Our Control occurs which affects us performing the Agreement after we have supplied the Car to you we will notify you as soon as possible. Our responsibilities to you under the Agreement will be temporarily delayed and the agreed date by which we must perform our responsibilities will be extended until the Circumstance Outside of Our Control has ended.</li>
          </ol>
        </li>
        <li>
          Names and Trademark Rights
          <ol>
            <li>All of the information, design, data, consent and other software related to intellectual property rights and such, is at all times the sole property of Seez ApS or our Partners. The content is protected by Danish law, and any violation of Seez ApS or our partners intellectual property rights, may result in civil as well as criminal actions and penalties.</li>
            <li>You may not transfer any of your rights or obligations under the Agreement to any other person. We may transfer our rights or obligations under the Agreement to another person provided that this will not affect your rights under the Agreement.</li>
          </ol>
        </li>
        <li>
          Miscellaneous
          <ol>
            <li>If a court finds part of the Agreement is illegal, invalid or otherwise unenforceable, the remaining parts of the Agreement will continue in full force and effect.</li>
            <li>The Agreement does not give rise to rights that are enforceable by any person who is not party to the Agreement.</li>
            <li>If we do not exercise or enforce a right under these Terms against you at any time, this does not prevent us from doing so at a later date.</li>
            <li>The Agreement sets out the entire agreement between You and Us relating to the purchase of the Car. The Agreement will replace any prior discussions or agreement between You and Us relating to the purchase of the Car.</li>
          </ol>
        </li>
        <li>
          Disputes
          <ol>
            <li>In the event of disputes arising on the basis of the services provided on the Seez Platform or other issues from the relationship between the user and Seez, we encourage you to contact us to find an amicable solution.</li>
            <li>If the dispute cannot be settled amicably, the user can lodge a complaint to The Danish Competition and Customer Authority, Center for Complaint Resolution, Carl Jacobsens Vej 35, 2500 Valby. The complaint can be submitted electronically via <a href="www.forbrug.dk">www.forbrug.dk</a>. Customers can also complain to the EU Complaint Portal at <a href="www.ec.europa.eu/odr">www.ec.europa.eu/odr</a>.</li>
            <li>In the event a cannot be resolved by the above mentioned instances, the dispute shall be settled according to Danish Law and by the Courts in Denmark, with the City Court of Copenhagen as an agreed venue.</li>
          </ol>
        </li>
      </ol>`
}

export const QUALITY_PAGE = {
  title: 'Quality Assured from trusted partners',
  p1: 'We’ve set our standards so high that they can only be met by a special selection of cars from trusted dealers - the cars that are Almost New. And we vow to only offer cars that fit that standard.',
  p2: 'Every Almost New car has been hand-picked, inspected, and found worthy.',
  almostNewCar: {
    title: 'What’s an almost new car?',
    textArray: [
      'Almost new is a concept we firmly believe in.',
      'It’s a promise we make to you to only provide premium quality cars that are so close to perfect, they’re almost new.'
    ],
    listTitle: 'Every almost new car:',
    listItems: [
      'Is no more than <b>8 years old</b>',
      'Has less than <b>175,000 km</b> on its odometer',
      'Has <b>never</b> been in an accident',
      'Has a <b>minimum</b> one year warranty'
    ]
  },
  historyChecks: {
    title: 'History checks',
    textArray: [
      'Every car available for sale on our platform is dealer certified and has a full service history.',
      'We also go a step beyond and put our almost new cars through a series of rigorous and exhaustive inspections and checks to ensure that your future car gives you complete peace of mind.'
    ],
    listItems: [
      'Comes with a service history',
      'Has been serviced per manufacturer standards',
      'Has been inspected by our quality assurance experts',
      'Has been checked by certified mechanics'
    ]
  },
  needUsText: [
    'Our almost new cars are only the highest quality cars from certified trusted dealers across the country. Reach out if you want to learn more.',
    'We’re always happy to answer any questions you have.'
  ],
  popularQuestions: {
    title: 'Popular questions about quality assured',
    accordions: [
      {
        id: 'testDrive',
        title: 'Can I test drive the car?',
        text: ['Test drives are a thing of the past. Taking a car for a 30 minute spin is an inefficient way to test it. Instead, we offer you 14 days to take the car home and take your time assessing it in any way you think is best - be it driving it on different terrains, in varying weather conditions, or simply squeezing your entire family into it and driving around the block.',
          'As long as the car is undamaged, unaltered, and hasn\'t been driven more than 500 km, we\'ll take it back and give you a full refund should you choose to return it within the 14 day limit.']
      },
      {
        id: 'imperfections',
        title:
          'How can I see any imperfections on a car before I complete my purchase?',
        text: ['Our almost new cars are as close to new as possible. However, if any scratches or dents do exist, we will be upfront and highlight them in the photos provided.']
      },
      {
        id: 'condition',
        title: 'What if my car isn’t in the condition I expected',
        text: ['Our promise is to uphold the highest levels of honesty and transparency. We will do everything we can to ensure that the condition of the car is reported factually and accurately.',
          'However, if for any reason you receive a car that does not match the expected condition, we will be there to support you every step of the way regardless of whether you choose to fix it or return it - as long as the return is within the 14 day period.']
      }
    ]
  }
}

export const DEALERS_SECTION = {
  title: 'We partner with premium dealers, so we can guarantee the best quality'
}

export const FOOTER_CARDS = {
  warranty: {
    title: '1 Year Warranty',
    description: 'Your car goes home with a free one-year full warranty. No premiums, no fuss.',
    icon: 'shield'
  },
  return: {
    title: '14 days return',
    description: 'You get 14 whole days to test drive your new car. If you don’t fall in love with it, send it back - no questions asked.',
    icon: 'repeat'
  },
  quality: {
    title: 'Quality assured',
    description: 'Our selection of cars comes only from trusted premium partners. Certified, inspected, and even tire kicked.',
    icon: 'medal'
  }
}

export const PAGE_FOOTER = {
  inYourHands: {
    title: 'Once it’s in your Hands',
    textArray: [
      'While you have 14 days to test drive your new car, all our cars comes with our quality assurance and a minimum 1 year warranty. We’re there for your car needs every step of the way.'
    ],
  },
  peaceOfMind: {
    title: 'For Your Peace of Mind',
    textArray: [
      'We vow to only offer the highest quality cars, and to guarantee that your car is up to standard.',
      'All our cars comes with a  14-day free return policy and minimum 1 year warranty.'
    ]
  },
  needUs: {
    title: 'Here when you need us',
  },
  carSearch: {
    text: 'Ready to hit the open road?',
    title: 'Let’s find your next car'
  }
}

export const FREE_DELIVERY = {
  title: 'Free delivery',
  headerText: [
    'Reach across the country and shop for the car of your dreams with absolutely no worry about where the car is located. We’ll deliver your new car straight to your doorstep.'
  ],
  covidText: 'To help keep you safe we fully sanitise the car.',
  deliveryMap: {
    title: 'Where do we deliver?',
    textArray: [
      'We offer free delivery everywhere in Denmark, except to non-bridged islands such as Samsø and Fanø.',
      // eslint-disable-next-line
      "We'll always do our best to bring the car to your door, but there will be occasions where due to restricted access, it won't be possible.",
      'In such cases, we work with you to find an accessible location that is most convenient to you. '
    ]
  },
  behindTheScenes: {
    title: 'Behind the scenes',
    listItems: [
      'Once your order is confirmed, the dealer starts preparing your car and getting it ready for delivery.',
      'This may take up to 8 days to kickstart registration and to ensure that everything is as it should be.',
      'Our delivery experts then pick the car up and take it to our local delivery hub where we make sure that everything is up to our “almost new” standard. For example, if your car has a servicing due within the next 5,000 km, we ensure that it is serviced before we deliver it to you. '
    ]
  },
  deliveryDay: {
    title: 'The delivery day',
    text: 'Delivery should take no more than 20 minutes. We’ll only need proof of ID and a quick signature and the car is yours to enjoy.',
    textArray: [
      'We’ll give you a call when we’re on the way to ensure that you’re available and that the delivery runs as smoothly as possible.',
      'Our experts are up to date with every detail of your car, inside and out. They give you a complete run-through of the car and answer all your questions.',
      'When your car is delivered, it comes with:'
    ],
    listItems: [
      'Registration documents',
      'The warranty certificate',
      'A fully up-to-date service book'
    ]
  },
  tradeIn: {
    title: 'The Trade-in',
    text: 'If you’re buying your new car with a trade-in, we will pick up the trade-in car at delivery and hand it over to the dealer. This way you will never be without a car and can immediately get back on the road with your new car.',
    buttonText: 'Read more about Trade-in'
  },
  documents: {
    title: 'Some Necessary Documents',
    listItems: [
      'For our peace of mind and yours, and to ensure that your car is delivered to you and only you, we request that you have a photo ID such as your driving licence or passport on hand.',
      // eslint-disable-next-line
      "If you've submitted a car as a trade-in, we will need:"
    ],
    cards: [
      // eslint-disable-next-line
      "The trade-in's registration documents",
      'The service book',
      'At least one set of keys',
      'The car manual - if applicable'
    ]
  },
  needUsText: [
    'Our team is there for you every step of the way. Our lines are open and we’re ready to answer any questions you might have and provide assistance with anything you need.',
    'We’re always happy to help.'
  ],
  popularQuestions: {
    title: 'Popular questions about free delivery',
    accordions: [
      {
        id: 'someoneElse',
        title: 'Can someone else receive my car?',
        text: ['While we do understand that sometimes life may get in the way, we cannot hand your car over to someone other than you. We need you to be present at handover, with your identification papers, so that you may sign receipt and get introduced to your car properly.',
          'Besides, we try to make the handover as magical as possible - you won\'t want to miss it.']
      },
      {
        id: 'timeSlot',
        title:
          'Will I be able to choose a preferred time slot?',
        text: ['Yes, you’ll be able to book your most preferred 2-hour slot at check out.']
      },
      {
        id: 'flatBed',
        title: 'Do you deliver on a flat bed or will the car be driven?',
        text: ['No one drives your car but you. We deliver your car in one of our dedicated transport trucks - fully washed and sanitized, and adhering to all covid standards and protocols.']
      },
      {
        id: 'badCar',
        title: 'What if I don\'t like my car when I receive it?',
        text: ['If for any reason you find that your car is not the perfect fit for you, you have 14 days to contact our customer support centre and we\'ll come back to pick it up and give you a full refund.',
          'If you traded in your old car, we will not be able to return it, but you may keep the full amount we offered.']
      }
    ]
  }
}

export const RETURN_PAGE = {
  title: '14-Day Return & Money-back guarantee',
  headerText: [
    'We believe that test driving a car for 30 minutes is not enough for you to really discover it. Take it home and see if it fits into your life to be 100% sure that this is the one.',
    'And if it’s not - send it back. Simply request a return within 14 days and you’ll get your money back – guaranteed.'
  ],
  returnCar: {
    title: 'How To Return a Car',
    text: 'Returning a car is so safe and easy, it only needs two steps.'
  },
  returnSteps: {
    step1: 'Step 1: Get in touch with one of our agents.',
    phoneText: 'You can reach our agents at +45 80 82 08 01 between 10:00 and 18:00 or;',
    emailText: 'Email suport@seez.dk and fill out the return form',
    buttonText: 'Download Return Form',
    step2: 'Step 2: Our team will work with you to arrange the car’s pick-up within 2 working days.'
  },
  finePrint: {
    title: 'The Fine Print',
    text: 'A 14 day test drive might sound too good to be true. But like everything in life, terms and conditions apply:',
    listItems: [
      'We can accept any car that shows normal use for the duration it was with you',
      'We can’t accept the car if more than 200 km have been added to the odometer.',
      'We can’t accept a car if it has been in an accident or was damaged in any way.',
      'We can’t accept a car that has been modified or altered.'
    ]
  },
  refunds: {
    title: 'Refunds',
    p1: 'Once we check at the car nd confirm that your car can be returned, our agents start the refund process.',
    p2: 'Your refund will be issued in the same method you used to pay for the car. If you applied for financing, you have a 14 day grace period to cancel the application. However, certain application fees may not be refundable.',
    cards: [
      {
        title: 'Bank Transfer',
        description:
          'A full refund will be credited to your bank account within 7 working days.',
        icon: 'coin'
      },
      {
        title: 'Financing',
        description:
          'We inform Santander and they contact you to start the process. Your down payment will be refunded fully to your bank account.',
        icon: 'bank'
      }
    ]
  },
  note: {
    title: 'Things to note:',
    text: 'As it stands, we will not be able to return your trade-in car to you if you choose to return your new car. However, the amount we offered you for the trade-in, if it was used towards your down payment, will be refunded to you in full.'
  },
  needUsText: [
    'Our 14-Day Return & Money Back Guarantee gives you 14 days after delivery to test drive your car and get to know it. Still unsure? Just give us a call.',
    'We’re always happy to answer any questions you have.'
  ],
  popularQuestions: {
    title: 'Popular questions about 14 days return',
    accordions: [
      {
        id: 'collectCar',
        title: 'Do you collect my car free of charge?',
        text: ['Yes, we will collect your car from the same location we delivered it to, completely free of charge.', 'For more details, refer to our '],
        html: ['', '<a href="/terms">terms and conditions.</a>']
      },
      {
        id: 'howLong',
        title: 'How long does it take for you to collect the car?',
        text: ['Once you\'re certain the car is not the right fit for you, email us at support@seez.dk',
          'One of our specialists will call you within one working hour to coordinate with you and schedule a date and time for pick up that work best. Depending on your location, collection may be up to 72 hours later.']
      },
      {
        id: 'stayHome',
        title:
          'Do I need to be home when the car is collected?',
        text: ['Yes. You need to be present to sign over the car once our agent inspects it and approves it for return.']
      },
      {
        id: 'responsible',
        title: 'Who is responsible for insuring the car until it is collected?',
        text: ['It is your responsibility to keep the car insured until it is collected.']
      }
    ]
  }
}

export const WARRANTY_PAGE = {
  title: '1 Year Warranty',
  headerText: [
    'Buying an almost new car means zero compromise. Our cars come directly from premium certified dealers across the country, and they all come with a minimum of 1-year dealer warranty.',
    'We want you to know that we’ve got you covered.'
  ],
  whatIsWarranty: {
    title: 'What’s in a Warranty?',
    textArray: [
      'When you buy a car from Seez, you can rest assured that it’s an investment worth making. We hand-select premium quality cars that are certified and protected by their factory warranty.',
      'And we guarantee that as long as they’re under warranty, the original dealer will fix anything that goes wrong with the core components of your car, completely free of charge.'
    ]
  },
  servicing: {
    title: 'Servicing Your Car',
    textArray: [
      'All our cars come pre-serviced by the official dealer. We make sure that any car you buy will not have a servicing due for at least 5.000 km.',
      'Once your car’s service light indicator comes on, simply call the number on your car’s service manual and book an appointment at a certified dealer to maintain your warranty.',
      'If your car needs servicing before you’ve driven 5.000 km with it, give us a call and we’ll take care of it for you.'
    ]
  },
  needUsText: [
    'If you’re still unsure whether your car has been serviced, just ask your delivery specialist or reach out to us.',
    'We’re always happy to answer any questions you have.'
  ],
  popularQuestions: {
    title: 'Popular questions about warranty',
    accordions: [
      {
        id: 'insurance',
        title: 'Is insurance included?',
        text: ['As of yet, we have not found the right insurance partner and cannot offer you quality premiums. This is something we\'re currently working hard to rectify. However, you can still purchase your insurance of choice separately once the car is yours.']
      },
      {
        id: 'certifications',
        title: 'Will I be given any certifications with my car?',
        text: ['When your car is delivered, it will come with:'],
        list: ['Registration documents', 'The warranty certificate', 'A fully up-to-date service book']
      },
      {
        id: 'serviceBook',
        title:
          'Where is my car’s service book?',
        text: ['If your car has a physical service book, it will be in your car\'s glove compartment. If it\'s electronic, you will need to download and access it through the display screen. In both cases, our delivery specialist will show you how to find it as well as highlight the last service date and km range and when the next upcoming one is due.']
      },
      {
        id: 'service',
        title: 'How will I know when to service my car?',
        text: ['Your car’s handbook, offered as part of your welcome pack, will have detailed recommendations from the manufacturer on how and when to service your car.']
      }
    ]
  }
}

export const ABOUT_SEEZ = {
  title: 'About Seez',
  headerText: ['Seez is a new way of buying cars fully online.',
    'Our aim is to help you unlock a world where you can buy a car, trade-in your old one, and get financing and insurance with full confidence - all from the comfort of your home.'],
  needUsText: ['If you’re still unsure whether your car has been serviced, just ask your delivery specialist or reach out to us.', 'We’re always happy to answer any questions you have.'],
  popularQuestions: {
    title: 'Popular questions about quality assured',
    accordions: [
      {
        id: 'testDrive',
        title: 'Can I test drive the car?',
        text: ['Test drives are a thing of the past. Taking a car for a 30 minute spin is an inefficient way to test it. Instead, we offer you 14 days to take the car home and take your time assessing it in any way you think is best - be it driving it on different terrains, in varying weather conditions, or simply squeezing your entire family into it and driving around the block.',
          'As long as the car is undamaged, unaltered, and hasn\'t been driven more than 200 km, we\'ll take it back and give you a full refund should you choose to return it within the 14 day limit.']
      },
      {
        id: 'imperfections',
        title:
          'How can I see any imperfections on a car before I complete my purchase?',
        text: ['Our almost new cars are as close to new as possible. However, if any scratches or dents do exist, we will be upfront and highlight them in the photos provided.']
      },
      {
        id: 'condition',
        title: 'What if my car isn’t in the condition I expected',
        text: ['Our promise is to uphold the highest levels of honesty and transparency. We will do everything we can to ensure that the condition of the car is reported factually and accurately.',
          'However, if for any reason you receive a car that does not match the expected condition, we will be there to support you every step of the way regardless of whether you choose to fix it or return it - as long as the return is within the 14 day period.']
      },
      {
        id: 'history',
        title: 'Do your cars come with a service history?',
        text: ['Yes. All the cars on our platform have been serviced in line with manufacturer recommendations and standards.']
      }
    ]
  },
  customerTeamTitle: 'Meet some of our customer champions',
  team: {
    Amanda: {
      occupation: 'CX Specialist',
      quote: '“I love being able to use my customer service skills to help people discover something that makes their lives better.”'
    },
    Elisa: {
      occupation: 'CX Specialist',
      quote: '“The best part of my job is meeting new people and getting to learn what they want so I can deliver happiness to them.”'
    },
    Kristian: {
      occupation: 'Customer Service Manager',
      quote: '“Customer experience is about the experience we deliver to our customers, and I always aim to deliver the best experience possible.”'
    },
    Nikki: {
      occupation: 'Delivery Specialist',
      quote: '“I grew up around cars, and delivering great quality cars and being able to walk satisfied customers through their cars is a feeling I really love.”'
    }
  },
  review: {
    title: 'Vi ledte efter ny familiebil og faldt…',
    text: 'Vi ledte efter ny familiebil og faldt over Seez.dk De havde et stort udvalg af biler og da vi havde brug for hjælp til at finde frem til den bil som passede til vores familie, stod deres kundeservice klar med det samme. Vi kan klart anbefale dem!',
    date: 'For 7 dage siden'
  },
  ourPromise: {
    title: 'Our Promise to You',
    description: 'When you buy an almost new car from us, you don’t just buy quality, you buy our promise - that we will do everything we can to provide a transparent and straightforward car buying experience for you.',
    cards: [
      {
        id: 'Delivery',
        title: 'Free Delivery',
        text: 'Reach across the country and shop for the car of your dreams with absolutely no worry about where the car is located.'
      },
      {
        id: 'Return',
        title: 'Money-back Guarantee',
        text: 'We believe that a 30 minute test drive is not enough for you to really experience a car. We give you 14 days.'
      },
      {
        id: 'Quality',
        title: 'Quality Assured from Trusted Partners',
        text: 'We’ve set our standards so high that they can only be met by a special selection of cars from trusted dealers.'
      },
      {
        id: 'Warranty',
        title: '1 Year Warranty',
        text: 'Buying an almost new car means complete peace of mind. Our cars come with a minimum 1 year warranty.'
      }
    ],
    readMore: 'Read More'
  },
  news: {
    title: 'Read about us in the news',
    pressRelease: 'Read press release'
  }
}

export const WHY_SEEZ = {
  title: 'How does it works?',
  headerText: [
    'We’ve all been there. We’ve all had to go out and buy a car. We know how confusing and stressful it can be - all the decisions involved.',
    'So we’ve made it our mission to empower you to buy your next car with confidence. We’ve made it so simple and convenient that when you buy a can from us, you feel only the joy of buying a car - the excitement.'
  ],
  needUsText: ['If you’re still unsure whether your car has been serviced, just ask your delivery specialist or reach out to us.', 'We’re always happy to answer any questions you have.'],
  popularQuestions: {
    title: 'Popular questions about how it works',
    accordions: [
      {
        id: 'seez',
        title: 'What is Seez?',
        text: ['Seez is a new way to buy an almost new car entirely online. Our platform allows you to search through a multitude of cars from dealerships across the country, purchase the one you like, apply for financing, trade in your old car, and sign all contracts from the comfort of your home. We\'ve turned car buying into an easy, haggle-free, minute-long process. And on top of it all, your car is delivered straight to your door and you get 14 days to decide if you want to keep the car or return it, no questions asked.']
      },
      {
        id: 'accident',
        title:
          'Do you sell cars that have been in an accident?',
        text: ['No. We\'ve set the highest standards on the cars we categorize as almost new - standards that cannot be met by cars that have been reported in accidents. Any car that has been in anything from a fender-bender to being officially categorized as a write-off, is automatically rejected by our Selection Experts and will not be listed on our platform.']
      },
      {
        id: 'negotiable',
        title: 'Are prices negotiable?',
        text: ['When you buy a car from Seez, you also buy the knowledge that we\'ve done the negotiating for you. While our prices are non-negotiable, we do promise that our cars are listed at the best possible price you could get. Besides, isn\'t the best part about buying online having the ability to purchase something quickly, efficiently, and with absolutely no haggling?']
      },
      {
        id: 'testDrive',
        title: 'Can I test drive the car?',
        text: [
          'Test drives are a thing of the past. Taking a car for a 30 minute spin is an inefficient way to test it. Instead, we offer you 14 days to take the car home and take your time assessing it in any way you think is best - be it driving it on different terrains, in varying weather conditions, or simply squeezing your entire family into it and driving around the block. As long as the car is undamaged, unaltered, and hasn\'t been driven more than 500 km, we\'ll take it back and give you a full refund should you choose to return it within the 14 day limit.'
        ]
      }
    ]
  },
  buyBrowse: {
    title: 'Browse & Buy a Car Entirely Online',
    cards: [
      {
        title: 'It’s quick',
        text: 'Fill in a quick, 20 minute application that tells us who you are, how you want to pay, and whether you have a trade-in.'
      },
      {
        title: 'It’s Convenient',
        text: 'Sign all the documents from the comfort of your home and we’ll deliver your car straight to your doorsteps.'
      },
      {
        title: 'It’s Safe',
        text: 'You have 14 days to decide. You can choose to return the car and everything you paid is 100% refundable.'
      }
    ]
  },
  review: {
    text: 'Process made so easy. Cheerful customer service. The future of car buying.',
    customer: 'Seez customer'
  },
  howItWorks: {
    title: 'How Does it Really Work?',
    text: 'Find your dream car and buy it completely haggle-free, with no hidden fees.',
    steps: [
      {
        id: 'search',
        image: 'find',
        title: 'Find Your Perfect Car',
        textArray: [
          'Browse our wide range of quality cars from the palm of your hand.',
          'Our cars are sourced from premium dealers and vetted by our inspection team. All our cars comes with a minimum 1 year warranty and a 14 day full money-back guarantee.',
          'Our cars are priced exactly like you would have gotten it from the dealerships to guarantee that you always get the greatest value. '
        ],
        linkText: 'Browse cars'
      },
      {
        id: 'quality',
        image: 'reserve',
        title: 'We reserve it for you',
        textArray: [
          'Once you submit your application we will reserve the car for you.',
          'Our application is easy and takes no more than 20 minutes. We will ask for:',
          '1. Some of your personal details',
          '2. Your trade-in details, if you want to swap cars',
          '3. Your preferred payment method - we can help you apply for financing if needed',
          '4. Your chosen delivery address'
        ],
        linkText: 'Check our quality assurance criterias'
      },
      {
        id: 'delivery',
        image: 'deliver',
        title: 'Get it Delivered',
        textArray: [
          'Receive your car at any location of your choosing.',
          'Once your order is complete, just sit back, relax, and wait for your new car to make its way to you. We’ll deliver the car to almost anywhere in Denmark - as long as you’re there (with an ID) to receive it.',
          'If you’ve added a trade-in, we’ll give it a quick inspection and cart it off to its new home.'
        ],
        linkText: 'Learn about free delivery'
      },
      {
        id: 'return',
        image: 'decision',
        title: '14 Days to Decide',
        textArray: [
          'We give you 14 days to test drive your car and decide if you like it.',
          'Take your time, drive it around town, take your family on a road trip, or sit in traffic and test the comfor level of the seats. As long as you don’t damage it, change it, or drive above our maximum mileage limit, you can return it and get a full refund.',
          'If you applied for financing, your agreement will be cancelled and your down payment refunded.'
        ],
        linkText: 'More about 14 days money back guarantee'
      }
    ]
  },
  finance: {
    cards: [
      {
        id: 'Financing',
        title: 'Finance a used car',
        text: 'Apply for financing with our trusted banking partner. Simply choose from monthly payment options that suit you and we will give you a decision within one working day.',
        providedBy: 'Financing provided by'
      },
      {
        id: 'TradeIn',
        title: 'Trade in your old car',
        text: 'Fill in a quick application about your trade-in car and get an offer online from our partner dealers within hours. If you are happy with the offer we will pick it up from your door when delivering your new car.'
      }
    ]
  }
}

export const LISTING_DETAILS_COMPONENT_TRANSLATIONS = {
  //   error_loading_financing: 'Error loading financing price',
  error_no_listing_heading: 'It seems that this listing is no longer available',
  error_no_listing_paragraph: 'Unfortunately, the listing is no longer available. In the meantime, you can search for similar listings.',
  search: 'Search ({total} cars)',
  error_loading_financing: 'Financing price error',
  unable_load_financing: 'We weren\'t able to load the financing price please try again in sometime.',
  personal: 'Personal',
  economy_environment: 'Economy and Environment',
  service_history: 'Service History',
  economy: 'Economy',
  net_weight: 'Net Weight',
  environment: 'Environment',
  authority_safety_rating: 'Authority Safety Rating',
  nox_emissions: 'NOx emissions',
  co2_emissions: 'Co2 emissions',
  num_seats: 'Number of seats',
  length: 'Length',
  height: 'Height',
  width: 'Width',
  drive_type: 'Drive type',
  engine_size: 'Engine size',
  number_airbags: 'Number of airbags',
  num_cylinder: 'Number of cylinders',
  towbar: 'Towbar',
  battery_consumption: 'Battery Consumption',
  anual_green_tax: 'Anual green tax',
  half_anual_green_tax: 'Half yearly tax',
  monthly_green_tax: 'Monthly green tax',
  latest_service_date: 'Latest service date',
  next_service_date: 'Next service date',
  km_last_service: 'Km at last service',
  num_prev_owners: 'Number of previous owners',
  imported: 'Imported',
  demo_bil: 'Demo car',
  first_reg_date: 'First registered date',
  performance: 'Performance',
  registration_type: 'Category',
  body_type: 'Body type',
  consumption: 'Fuel consumption',
  link_copied: 'Link copied to clipboard',
  partner_dealer: 'Partner Dealer',
  show_all_photos: 'Show all photos',
  trade_it_in: 'Trade it in',
  use_your_old_car: 'Use your old car to help pay for your new car',
  learn_about_trade_in: 'Learn more about trade-in',
  buy_now: 'Buy now',
  full_price: 'Full Price',
  monthly_price: 'Monthly Price',
  quality_assured: 'Quality Assured',
  money_back: 'Money-back Guarantee',
  browse_all_vehicles: 'Browse all vehicles',
  get_valuation: 'Get your trade-in valuation',
  download_brochure: 'Download Brochure',
  brochure: 'Brochure',
  save: 'Save',
  share: 'Share',
  vat_include: 'Van inc. VAT ',
  vat_plus: 'Van plus VAT',
  van: 'Van',
  cash: 'Cash',
  financing_from: 'Financing',
  financed_by: 'Financing supplied by',
  month: 'month',
  month_short: 'month',
  monthly_payment_info: `Monthly payment 2,721 kr., term 84 months, variable borrowing rate
            1.26%. APR 4,56%. Total credit costs: 32,565 kr. Down payment:
            48.980 kr. Total amount to be paid: 228.485 kr.`,
  get_started: 'Get started',
  login_to_buy: 'Login to Buy',
  resume_checkout: 'Resume Checkout',
  car_reserved: 'Vehicle reserved',
  reserved: 'Reserved',
  sold: 'Sold',
  coming_soon: 'Coming Soon',
  color: 'Color',
  kilometrage: 'Kilometrage',
  horsepower: 'Horsepower',
  km: 'km',
  transmission: 'Transmission',
  fuel: 'Fuel',
  registration: 'Registration',
  car_financing: 'Car Financing',
  finance_your_car: 'Finance your car',
  quick_specs: 'Quick Specs',
  completely_online: 'completely online',
  we_made_get_loan_easy_msg: ` We've made getting a car loan as easy and efficient as possible. With
          Seez, you can now apply entirely online and get the best interest
          rates within 24 hours.`,
  enter_your_down_payment: `Enter your down payment and choose your loan duration to estimate your
          monthly payment`,
  seez_benefits_you_get: 'Seez benefits you get with this car',
  quality_assurance: 'Quality Assurance',
  we_believe_value_almost_new: 'We believe in the value of almost new',
  fourteen_days_return: '14-Day Return',
  we_believe_full_transparency: 'We believe in full transparency',
  one_year_warranty: '1 Year Warranty',
  we_believe_full_peace_mind: 'We believe in full peace of mind',
  free_delivery: 'Free Delivery',
  we_believe_full_convenience: 'We believe in full convenience',
  general_information: 'General Information',
  year: 'Year',
  range: 'Range',
  fuel_consumption: 'Fuel Consumption',
  l_km: 'L/100 km',
  km_l: 'km/L',
  green_tax: 'Green tax',
  fuel_type: 'Fuel type',
  reference_number: 'Reference Number',
  technical_specs: 'Technical Specifications',
  acceleration: 'Acceleration',
  interior_material: 'Interior material',
  top_speed: 'Top speed',
  airbags: 'Airbags',
  number_of_gears: 'Number of gears',
  abs: 'ABS',
  available: 'Available',
  undisclosed: 'Undisclosed',
  cylinders: 'Cylinders',
  dimension_capacity: 'Dimensions / Capacity',
  weight: 'Weight',
  max_attachment: 'Max. attatchment',
  max_attachment_unbraked: 'Max. attachment unbraked',
  load_capacity: 'Load capacity',
  number_of_doors: 'Number of doors',
  comfort_technology: 'Comfort / Technology',
  design: 'Design',
  safety_environment: 'Safety / Environment',
  equipment_details: 'Equipment list',
  you_might_like: 'You might also be interested in',
  equipment: 'Equipment',
  price: 'Price',
  description: 'Description',
  read_more: 'Read More',
  read_less: 'Read Less',
  free_tradein_notes: 'Things to Note',
  how_does_it_work: 'How does buying a car from Seez work?',
  watch_know_more: 'Watch video to know more',
  free_tradein_explanation: '<p>Get a quick trade-in valuation of your old car to get an estimate of how much can go towards your new car.</p><p>The trade-in valuation will be preformed by the certified dealers evaluation experts.</p><p>A final value of your trade-in will be provided once we have collected the fine details of your car.</p>',
  Ford: {
    id: 17,
    name: 'Ford',
    main: 'Ford is a car brand with a long history and thus also a lot of experience when it comes to producing cars. Ford has a strong and attractive selection of cars, which include the Ford Ka, Fiesta, Focus, Kuga as well as the C-Max and S-Max models. Ford is on the list of the most popular car brands in Denmark and is strongly represented in all categories - from the smaller city car to the large SUV.'
  },
  Renault: {
    id: 13,
    name: 'Renault',
    main: 'Renault is a French car brand that was established in 1898 by Louis Renault. Renault is French engineering at its very best. Today, the car brand is behind a large number of lifestyle cars and vans, which have experienced great demand from both domestic and abroad. We fully understand this demand and have therefore gathered a wide selection of used Renault top-drawer cars, carefully selected by our employees. Find exactly the car that you are looking for with Seez.'
  },
  Dacia: {
    id: 30,
    name: 'Dacia',
    main: 'Dacia is a car brand with a long history and thus also a lot of experience when it comes to car production. The first Dacia car saw the light of day in 1966 in Romania, where the goal was to make modern and reliable cars available to the Romanian people. Since then, Dacia has become part of French Renault, but it is only in recent years that the car brand has gained a foothold in the Danish market.',
  },
  Mercedes: {
    id: 23,
    name: 'Mercedes-Benz',
    main: `At Seez we know that Mercedes is the epitome of luxury, stylish design, and drivability of the top drawer. Mercedes is available as MPV, station wagon, SUV, coupé, hatchback, sedan, and convertible, and we sell the most popular models, which include the A-Class, C-Class and CLK-Class. Models that are also available in an AMG version, which offers even higher performance.
Mercedes is a car brand that works under a motto of "The Best or Nothing", and this is very much something they live up to. In other words, with a good used Mercedes, you are guaranteed a car in a stylish design that oozes character, and at the same time has a high performance that ensures you the best driving experience - every time.`,
  },
  we_make_your_trade_in_easy: 'We make your Trade-in’s easy',
  fill_out_your_details_and_quick: 'Fill out your details and we’ll give you a quick valuation.',
  start_valuation: 'Start Valuation',
  learn_more_about_trade_in: 'Learn more about trade-in',
  trade_in_your_car: 'Trade-in your car with Seez',
  to_get_your_trade_In_price: 'To get your trade-in price, start by telling us your car reg.',
  car_details_trade_in: 'Car Details',
  fill_in_your_cars_information: 'Fill in your car’s information as accurately as possible.',
  make: 'Make',
  model: 'Model',
  variant: 'Variant',
  modelYear: 'Model Year',
  registrationDate: 'Registration Date',
  registrationType: 'Registration Type',
  more_details: 'More Details',
  has_your_car_been_in: 'Has your car been in an accident?',
  no: 'No',
  yes: 'Yes',
  does_your_car_have_outstanding_financing: 'Does your car have outstanding financing?',
  what_your_car_current_condition: 'What’s your car’s current condition?',
  poor: 'Poor',
  good: 'Good',
  average: 'Average',
  great: 'Great',
  carConditionPoorDef: 'Poor means the car is in a bad condition. There is significant damage to its exterior and interior. It is in need of a good repair or better yet, retirement.',
  carConditionAverageDef: 'Average means the car’s best years are far behind it. It runs, but the exterior is banged up and the interior is somewhat old and scruffy. Mechanically, the car might have a couple of issues.',
  carConditionGoodDef: 'Good means the car is running smoothly, but it isn’t at its peak anymore. The exterior may have small scratches or dents and the interior shows some wear and tear.',
  carConditionGreatDef: 'Great means the car is in close to perfect condition. The exterior is perfect or has minor scratches and the interior is clean and has no stains or smells. Mechanically, it runs without a hitch.',
  i_accept_privacy_policy: 'I accept that I will receive emails from Seez about offers and other services provided by seez.',
  get_my_trade_in_value: 'Get my trade-in value',
  how_many_keys: 'How many keys are available',
  number_of_keys: 'Number of keys',
  what_is_your_email_address: 'What\'s your email address?',
  we_ll_send_an_email_you: 'We\'ll send an email of your quote to you',
  please_enter_email: 'Please enter your email address',
  please_enter_outstanding_amount: 'Please enter outstanding financing amount',
  thanks_you_for_submitting: 'Thank you for submitting your trade-in',
  we_ll_contact_you_via_via_email: 'We’ll contact you via email once we have a qoute for you vehicle',
  done: 'Done',
  condition: 'Condition',
  regTypeVanWithoutVat: 'Van without VAT',
  regTypeVanWithVat: 'Van with VAT',
  regTypePersonal: 'Personal',
  regTypeLeasing: 'Leasing',
  please_enter_phone: 'Phone number',
  please_enter_your_name: 'Full name',
  vehicle_images: 'Vehicle Images',
  upload: 'Upload ',
  front_back_interior: 'front, back, interior ',
  and: ',and ',
  damage: 'damage ',
  pictures_of_your_car: 'pictures of your car so we can give you the best offer possible.',
  drag_your_file: 'Drag your trade-in vehicle images to upload them or ',
  browse_file: 'Browse files',
  you_should_upload_images: 'You should upload your car\'s pictures',
  incl_delivery: 'Incl. delivery',
  got_questions_title: 'Got questions',
  got_questions_paragraph: 'Our dedicated customer support team will be in touch with you shortly to provide answers to all your inquiries',
  contact_us: 'Contact us',
  contact_us_modal_pagraph_one: 'For more information on this car, call',
  contact_us_modal_pagraph_two: 'and state the following reference number'
}